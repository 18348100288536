.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 52;
  background: rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.cb-modal {
  overflow: hidden;
  z-index: 53;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cb-large {
  width: 75%;
  max-width: 900px;
}

.cb-medium {
  width: 50%;
  max-width: 650px;
}

.cb-small {
  width: 35%;
  max-width: 350px;
}

.cb-modal-body {
  width: 100%;
  max-height: 90vh;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
